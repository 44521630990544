/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it// @flow
require('./static/base16-railscasts-dark.css')
const {storage} = require('local-storage-fallback')

exports.onClientEntry = () => {
  // Set storage to local storage if it exists, otherwise use local-storage-fallback
  window.storage = storage
}
