// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-review-page-jsx": () => import("/Users/jacobyoung/jry/jry.io/src/templates/review-page.jsx" /* webpackChunkName: "component---src-templates-review-page-jsx" */),
  "component---src-templates-social-post-jsx": () => import("/Users/jacobyoung/jry/jry.io/src/templates/social-post.jsx" /* webpackChunkName: "component---src-templates-social-post-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("/Users/jacobyoung/jry/jry.io/src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/jacobyoung/jry/jry.io/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-404-jsx": () => import("/Users/jacobyoung/jry/jry.io/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("/Users/jacobyoung/jry/jry.io/src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-now-jsx": () => import("/Users/jacobyoung/jry/jry.io/src/pages/now.jsx" /* webpackChunkName: "component---src-pages-now-jsx" */),
  "component---src-pages-projects-jsx": () => import("/Users/jacobyoung/jry/jry.io/src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-reviews-jsx": () => import("/Users/jacobyoung/jry/jry.io/src/pages/reviews.jsx" /* webpackChunkName: "component---src-pages-reviews-jsx" */),
  "component---src-pages-social-jsx": () => import("/Users/jacobyoung/jry/jry.io/src/pages/social.jsx" /* webpackChunkName: "component---src-pages-social-jsx" */),
  "component---src-pages-writing-jsx": () => import("/Users/jacobyoung/jry/jry.io/src/pages/writing.jsx" /* webpackChunkName: "component---src-pages-writing-jsx" */)
}

exports.data = () => import("/Users/jacobyoung/jry/jry.io/.cache/data.json")

